#customers-review {
  .owl-carousel {
    .owl-stage {
      .item {
        .box {
          padding-bottom: 5px;

          .user {
            display: inline-block;
            background: white;
            text-align: center;
            border-radius: 50%;
            height: 110px;
            width: 110px;
            box-shadow: 0px 3px 2px 0px #999999ba;
            position: relative;
            margin: 0 auto;
            margin-left: 10px;
            margin-bottom: 5px;
            background: #e31000;


            

            

            &:after {
              content: '';
              position: absolute;
              background: white;
              width: 70px;
              height: 70px;
              border-radius: 50%;
              box-shadow: 2px 3px 6px 0px #999;
              left: -8px;
              top: -5px;
            }

            .img {
              border: 5px solid white;
              border-radius: 50%;
              object-fit: cover;
              width: 90px !important;
              height: 90px;
              margin: auto;
              top: 50%;
              left: 50%;
              position: absolute;
              transform: translate(-50%, -50%);
              z-index: 2;
            }
          }

          

          .content {
            padding-left: 25px;

            p {
              font-size: 1rem;
              font-weight: 400;
              position: relative;

              

              /* &:after {
                content: '';
                background-image: url("https://img-flb.flebo.in/img/assets/quote-icon.png");
                width: 45px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 100%;
                position: absolute;
                top: -35px;
                left: -34px;
                
              }

              &:before {
                content: '';
                background-image: url("https://img-flb.flebo.in/img/assets/quote-icon.png");
                width: 45px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: 100%;
                position: absolute;
                bottom: -31px;
                right: 0px;
                transform: rotate(180deg);
              } */
            }

            h2 {
              font-size: 1.2rem;
              font-weight: 700;
            }


            .start-rating {
              .fa {
                color: orange;
                font-size: 1.6rem;
                margin-right: 4px;
              }
            }
          }
        }
      }
    }
  }

  .owl-theme {
    .owl-nav {
      opacity: 1;
      position: absolute;
      right: 3rem;
      top: -9.8rem;

      

      button {
        color: black;

        &:hover {
          color: black;
          background-color: transparent;
        }
      }

      i {
        font-size: 4.5rem;
      }

      .fa-angle-right {
        &:after {
          content: '';
          width: 2.9rem;
          height: 0.4rem;
          background: #000;
          position: absolute;
          right: 0.5rem;
          top: 2.1rem;

          
        }
      }

      .fa-angle-left {
        &:after {
          content: '';
          width: 2.9rem;
          height: 0.4rem;
          background: #000;
          position: absolute;
          left: 0.5rem;
          top: 2.1rem;

          
        }
      }

      .owl-prev {
        left: 3rem;
        position: relative;
        width: 3.5rem;
        text-align: left;

        
      }

      .owl-next {
        right: -3rem !important;
        position: relative;
        width: 3.5rem;
        text-align: right;

        
      }
    }

    
  }

  .view-btn {
    position: absolute;
    top: -6.9rem;
    right: 5.2rem;
    z-index: 2;

    

    a {
      font-size: 1.6rem;
      font-weight: 500;
      color: white;
    }
  }
}



#customers-review .owl-carousel .owl-stage .item .box .user:after {
  content: "";
  position: absolute;
  background: #e31000;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  box-shadow: 2px 3px 6px 0 #999;
  left: -8px;
  top: -5px;
}

