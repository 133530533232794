@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Josefin Sans", sans-serif;
}

body {
  /* background-color: #08071b; */
  /* background-image: linear-gradient(90deg, #050d22, #000000); */
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  /* padding: 20px; */
  height: 100vh;
  /* overflow: hidden; */
}

.list {
  position: relative;
}
.list h2 {
  color: #fff;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.list ul {
  position: relative;
}
.list ul li {
  position: relative;
  left: 0;
  color: rgb(119, 112, 112);
  list-style: none;
  margin: 4px 0;
  border-left: 2px solid #f50057;
  transition: 0.5s;
  cursor: pointer;
}
.list ul li:hover {
  left: 10px;
}
.list ul li span {
  position: relative;
  padding: 8px;
  padding-left: 12px;
  display: inline-block;
  z-index: 1;
  transition: 0.5s;
}
.list ul li:hover span {
  color: #111;
}
.list ul li:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f50057;
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.5s;
}
.list ul li:hover:before {
  transform: scaleX(1);
}

.intro_video {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  max-height: 100vh; /* Ensures it doesn't exceed the viewport height */
  object-fit: cover; /* Ensures the video covers the entire element, cropping if necessary */
}

/* @keyframes backgroundMove {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
} */

.back {
  animation-duration: 20s;
  animation-timing-function: linear;
  /* animation-delay: 0s; */
  animation-iteration-count: infinite;
  animation-direction: normal;
  /* animation-fill-mode: none; */
  animation-play-state: running;
  animation-name: back;
  animation-timeline: auto;
  animation-range-start: normal;
  animation-range-end: normal;
}

.hello {
  background: linear-gradient(90deg, rgb(28, 9, 9) 29%, rgb(233, 116, 16) 68%);
  /* backgroundClip: text; */
}
.title {
  font-size: 24px;
  font-weight: 600;
}

.bg-1 {
  background: url(https://lh3.googleusercontent.com/d/19JPPogNR4d44RW3mnz1Xg3JkNDXcdCSk?authuser=0)
    center center / cover no-repeat;
}
.bg-2 {
  background: url(https://lh3.googleusercontent.com/d/11PDm1fIs8TlS0stzC7UcoBRhAdB-PXPo?authuser=0)
    center center / cover no-repeat;
}
.bg-3 {
  background: url(https://lh3.googleusercontent.com/d/1I8491_xmHYp9-1zBP72PcFI37d5FgmOY?authuser=0)
    center center / cover no-repeat;
}

.counter_number1 {
  background: linear-gradient(
    180deg,
    rgba(5, 13, 34, 0.036852240896359) 0%,
    rgba(62, 223, 197, 0.73) 100%
  );
  height: 30vh;
  min-height: 127px;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.counter_number2 {
  background: linear-gradient(
    180deg,
    rgba(5, 13, 34, 0.036852240896359) 0%,
    rgba(226, 104, 58, 0.89) 100%
  );
  height: 30vh;
  min-height: 127px;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (max-width: 768px) {
  .hello {
    font-size: 20px !important;
  }
  .hello2 {
    font-size: 14px !important;
  }
}
