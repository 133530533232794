.ftr_ie_cnt {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 60px;
}

/* @media (max-width: 1200px) {
    .ftr_lt_img, .ftr_rt_cnt li:after {
        display: block;
    }
}

@media (max-width: 1400px) {
    .ftr_lt_img {
        left: 70px;
    }
} */

.ftr_lt_img {
  width: 40%;
  position: relative;
  left: 0px;
}

.ftr_lt_img video {
  /* position: sticky; */
  top: 120px;
  width: 625px !important;
  /* width: 100%; */
  height: 600px;
  object-fit: cover;
}

/* @media (max-width: 1200px) {
    .ftr_rt_cnt {
        width: 100%;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        flex-wrap: wrap;
    }
} */

.ftr_rt_cnt {
  width: 50%;
  /* margin-left: auto; */
}

dl,
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* @media (max-width: 1200px) {
    .ftr_rt_cnt li {
        width: 32%;
        margin-bottom: 20px;
        text-align: center;
    }
} */

p {
  color: white;
  font-weight: 400;
}

h4 {
  color: white;
}

.ftr_rt_cnt li,
.hid {
  border: 1px solid rgba(112, 112, 112, 0.14);
  border-radius: 12px;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  background: rgba(255, 255, 255, 0.07);
  padding: 20px;
  max-width: 70%;
  margin-bottom: 50px;
  position: relative;
}

/* @media (max-width: 1200px) {
    .ftr_hd {
        display: block;
    }
} */

.ftr_hd {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 7px;
}

.ftr_rt_cnt li {
  border: 1px solid rgba(112, 112, 112, 0.14);
  border-radius: 12px;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  background: rgba(255, 255, 255, 0.07);
  padding: 20px;
  max-width: 70%;
  margin-bottom: 50px;
  position: relative;
}

.ftr_rt_cnt li:nth-child(odd):after {
  content: "";
  position: absolute;
  right: -130px;
  top: 80px;
  width: 118px;
  height: 100px;
  background-image: url(https://cdn-hnddh.nitrocdn.com/KqRjbeIbVVaUkfyLngjfpxqYLCgqfSCV/assets/images/optimized/rev-9aeff10/blocktunix.com/wp-content/uploads/2024/05/rt_arrow.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.ftr_rt_cnt li:nth-child(even):not(:last-child)::after {
  content: "";
  position: absolute;
  right: -130px;
  top: 80px;
  width: 118px;
  height: 100px;
  background-image: url(https://cdn-hnddh.nitrocdn.com/KqRjbeIbVVaUkfyLngjfpxqYLCgqfSCV/assets/images/optimized/rev-9aeff10/blocktunix.com/wp-content/uploads/2024/05/rt_arrow.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  right: unset;
  left: -130px;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.ftr_rt_cnt li:nth-child(even) {
  margin-left: auto;
}

/* Hide scrollbar for all browsers */
.ftr_rt_cnt {
  overflow: hidden; /* Hide scrollbar */
  overflow-y: scroll; /* Enable vertical scrolling */
}

/* Hide scrollbar for Webkit browsers (Chrome, Safari) */
.ftr_rt_cnt::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

/* Hide scrollbar for Firefox */
.ftr_rt_cnt {
  scrollbar-width: none; /* Hide scrollbar */
}

/* Hide scrollbar for Edge */
.ftr_rt_cnt {
  -ms-overflow-style: none; /* Hide scrollbar */
}

@media (max-width: 767px) {
  .video {
    display: none;
  }
}

@media (min-width: 768px) {
  .datacards {
    display: none !important;
  }
}
