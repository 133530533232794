.flex-container {
  display: flex;
  justify-content: space-around; /* Distribute space evenly between items */
  flex-wrap: wrap; /* Allow items to wrap if necessary */
}

.wrap {
  display: flex;
  align-items: center; /* Center items vertically */
  margin: 10px; /* Add some space between items */
}

.icon-wrapper {
  width: 120px;
  height: 120px;
  margin-bottom: 8px;
  background-color: #c6d5c957;
  border-radius: 9px 9px 9px 9px;
  box-shadow: 0 4px 5px 0 #0000001a;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title{
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
