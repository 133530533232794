/* .blg_flx {
  margin-top: 58px;
  align-items: stretch;
  justify-content: flex-start;
  gap: 0 25px;
} */

.blg_flx .blg_bx {
  margin-bottom: 10px;
  width: 31%;
}

@media (max-width: 767px) {
  .blg_flx .blg_bx {
    width: 90% !important;
    padding: auto;
    margin: auto;
    margin-bottom: 10px;
  }
}

/* .blg_bx {
  width: calc(33.33% - 30px);
  background-color: #060f1d;
  border-radius: 10px;
  border-radius: 20px;
  background: #060f1d 0% 0% no-repeat padding-box;
  border: 2px solid rgba(255, 255, 255, 0.09);
  opacity: 1;
  overflow: hidden;
  transition: 0.4s linear;
  min-height: 300px;
  position: relative;
} */

.blg_bx img {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  max-height: 200px;
  transition: all 0.6s ease-in-out;
  object-fit: cover;
  width: 100%;
  object-position: top left;
}

.blg_bx .blg_cnt {
  padding: 20px 20px 6px;
}

.blg_bx .blg_tim {
  color: var(--white-color);
  margin-bottom: 11px;
  display: block;
}

.blg_bx .blg_authore {
  color: var(--white-color);
  opacity: 0.4;
  margin-top: 10px;
  position: absolute;
  bottom: 16px;
}

.blg_flx {
  margin-top: 58px;
  display: flex;
  flex-wrap: wrap; /* Allows the cards to wrap onto new lines on smaller screens */
  gap: 0 25px;
  justify-content: flex-start;
}

.blg_bx {
  width: calc(33.33% - 30px); /* Adjust the width to fit three cards in a row */
  background-color: #060f1d;
  border-radius: 20px;
  background: #060f1d 0% 0% no-repeat padding-box;
  border: 2px solid rgba(255, 255, 255, 0.09);
  opacity: 1;
  overflow: hidden;
  transition: 0.4s linear;
  min-height: 300px;
  position: relative;
  margin-bottom: 20px; /* Adjust margin for spacing between rows */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .blg_bx {
    width: 100%; /* Full width on smaller screens */
    margin-bottom: 20px; /* Ensure some space between rows */
  }
}
