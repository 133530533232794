

.flip-card {
    background-color: transparent;
    width: 300px;
    height: 300px;
    perspective: 1000px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    /* background-color: #bbb; */
    color: black;
  }
  
  .flip-card-back {
    background-color: #2980b9;
    color: white;
    transform: rotateY(180deg);
    border-radius: 10px;
  }

  .flip-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Adjust gap between cards */
    justify-content: space-between; /* Ensure cards are spaced evenly */
}

.flip-card {
    background-color: transparent;
    width: 30%; /* Adjust to fit three cards in a row */
    height: 300px; /* Consistent height for cards */
    perspective: 1000px; /* Add perspective for 3D effect */
    box-sizing: border-box;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    transform: rotateY(0deg);
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.flip-card-front {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Hide overflowing content */
}

.flip-card-back {
  background-color: #2980b9;
  color: white;
    transform: rotateY(180deg);
    padding: 20px; /* Adjust padding as needed */
    box-sizing: border-box;
}

.flip-card-front img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.flip-card-front h3 {
    position: absolute;
    color: white;
    /* background-color: rgba(0, 0, 0, 0.5); */
    padding: 10px;
    border-radius: 5px;
    text-align: top;
}


.flip-card-front h3 {
  position: absolute;
  margin-top: 20px;
  top: 0; /* Aligns the h3 element to the top of its parent */
  color: white;
  padding: 50px 10px;
  border-radius: 5px;
  text-align: center; /* Ensures text is aligned to the left; 'top' is not a valid value for text-align */
  margin: 0; /* Optional: removes default margin if needed */
}
