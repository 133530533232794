.mega-menu-title {
    position: relative; /* Ensure that the pseudo-elements are positioned relative to this element */
    padding: 10px 0; /* Add padding to ensure spacing between text and lines */
    display: inline-block; /* Ensure that the element only takes up as much space as needed */
    color: #000; /* Default text color */
    transition: color 0.3s ease; /* Transition effect for text color */
}

.mega-menu-title:before {
    content: "";
    width: 70%;
    margin-left: 12px;
    position: absolute;
    height: 3px;
    background-color: #e6e6e6;
    top: 0; /* Position the line at the top of the element */
    left: 0; /* Align the line to the left of the element */
    transition: width 0.3s ease; /* Transition effect for width */
}

.mega-menu-title:after {
    content: "";
    width: 40%;
    position: absolute;
    margin-left: 12px;
    height: 3px;
    background-color: #0e8ece;
    top: 0; /* Position the line at the top of the element */
    left: 0; /* Align the line to the left of the element */
    transition: width 0.3s ease; /* Transition effect for width */
}

/* Hover state */
.mega-menu-title:hover:before {
    /* width: 100%;  */
}

.mega-menu-title:hover:after {
    width: 70%; 
    background-color: #FFA500; /* Change the line color to orange on hover */

}

.mega-menu-title:hover {
    color: #FFA500; /* Change text color to yellow on hover */
}

